import React from 'react';
import { Tldraw } from '@tldraw/tldraw';
import { useSyncDemo } from '@tldraw/sync';
import { useSync } from '@tldraw/sync';
import '@tldraw/tldraw/tldraw.css';

export default function App() {
        // Используем useSyncDemo для создания "комнаты"
          const store = useSyncDemo({ roomId: 'myapp-abc123-petr' });

	return (
		<div style={{ position: 'fixed', inset: 0 }}>
			<Tldraw store={store} />
		</div>
	);
}




 /*const WORKER_URL = `wss://wb.smartiq-pro.ru:5858`; // Адрес вашего WebSocket-сервера
const roomId = 'test-room'; // Замените на ваш идентификатор комнаты

export default function App() {
  // Используем useSync для подключения к вашему серверу
  const store = useSync({
    uri: `${WORKER_URL}/connect/${roomId}`, // Подключаемся к серверу по WebSocket
  });

  return (
    <div style={{ position: 'fixed', inset: 0 }}>
      <Tldraw store={store} /> 
    </div>
  );
}
*/