import React from 'react';
import { Tldraw } from '@tldraw/tldraw';
import { useSyncDemo } from '@tldraw/sync';
import '@tldraw/tldraw/tldraw.css';

export default function App() {
        // Используем useSyncDemo для создания "комнаты"
          const store = useSyncDemo({ roomId: 'myapp-abc123' });

	return (
		<div style={{ position: 'fixed', inset: 0 }}>
			<Tldraw store={store} />
		</div>
	);
}

